// RentDetails.js

// React components
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// UI components
import StandardPage from '../StandardPage';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import NumberInput from '../../ui/NumberInput';
import DateInput from '../../ui/DateInput';
import SelectInput from '../../ui/SelectInput';
import CreatableSelectInput from '../../ui/CreatableSelectInput';

// Data
import * as constants from '../../data/constants';

const RentDetails = () => {
    // Get the navigate and location hooks
    const navigate = useNavigate();
    const location = useLocation();
    const propertyListingData = location.state || {};

    // Set the initial state based on the propertyListingData
    const [price, setPrice] = useState(propertyListingData.rentDetails?.price || null);
    const [deposit, setDeposit] = useState(propertyListingData.rentDetails?.deposit || null);
    const [occupancyDate, setOccupancyDate] = useState(propertyListingData.rentDetails?.occupancyDate || null);
    const [letDuration, setLetDuration] = useState(propertyListingData.rentDetails?.letDuration || null);
    const [leaseLength, setLeaseLength] = useState(propertyListingData.rentDetails?.leaseLength || null);
    const [billsIncluded, setBillsIncluded] = useState(propertyListingData.rentDetails?.billsIncluded || null);
    const [furnishType, setFurnishType] = useState(propertyListingData.rentDetails?.furnishType || 0);
    const [suitableFor, setSuitableFor] = useState(propertyListingData.rentDetails?.suitableFor || []);
    const [restrictions, setRestrictions] = useState(propertyListingData.rentDetails?.restrictions || []);

    // Handle the form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        navigate('/listing/generate', {
            state: {
                ...propertyListingData,
                rentDetails: {
                    price,
                    deposit,
                    occupancyDate,
                    letDuration,
                    leaseLength,
                    billsIncluded,
                    furnishType,
                    suitableFor,
                    restrictions
                }
            },
        });
    };
    
    // Handle the back button
    const handleBack = () => {
        // Pass back the state including the RentDetails state
        navigate('/listing/features', {
            state: {
                ...propertyListingData,
                rentDetails: {
                    price,
                    deposit,
                    occupancyDate,
                    letDuration,
                    leaseLength,
                    billsIncluded,
                    furnishType,
                    suitableFor,
                    restrictions
                }
            }
        });
    };

    // Handle the lease length blur event
    const handleLeaseLengthBlur = (event) => {  
        const leaseLengthValue = parseInt(event.target.value, 10);  
        let durationType = '';  
  
        if (leaseLengthValue >= 12) {  
            durationType = { value: 'long', label: 'Long-term' };  
        } else if (leaseLengthValue >= 6 && leaseLengthValue < 12) {  
            durationType = { value: 'mid', label: 'Mid-term' };  
        } else if (leaseLengthValue < 6) {  
            durationType = { value: 'short', label: 'Short-term' };  
        }  
    
        // Update the letDuration state to the determined duration type  
        setLetDuration(durationType);  
    }; 

    return (
        <StandardPage>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                        <h1 className="mb-2">Rent Details</h1>
                        <p className="mb-2">Please enter the rent details for your property.</p>
                        <Form onSubmit={handleSubmit}>
                            <div className='property-listing-form'>
                            <h2>Financial Details</h2>  
                            <p className='form-subtitle'>Enter the monthly rental price and deposit amount required for leasing your property.</p>
                            <Row>
                                <Col md={6}>
                                    <NumberInput
                                        id="price"
                                        label={<>Price<span className='required-input'>*</span></>}
                                        min="0"
                                        max="999999"
                                        beforeText="£"
                                        afterText="pcm"
                                        required
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </Col>
                                <Col md={6}>
                                    <NumberInput
                                        id="deposit"
                                        label="Deposit Amount"
                                        min="0"
                                        max="999999"
                                        beforeText="£"
                                        value={deposit}
                                        onChange={(e) => setDeposit(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <h2>Lease Information</h2>  
                            <p className='form-subtitle'>Specify the length of the lease, duration type, furnishing details, and whether bills are included in the rent.</p>
                            <Row>
                                <Col md={6}>
                                    <NumberInput
                                        id="leaseLength"
                                        label="Lease Length"
                                        afterText="months"
                                        value={leaseLength}
                                        onChange={(e) => setLeaseLength(e.target.value)}
                                        onBlur={handleLeaseLengthBlur}  
                                    />
                                </Col>
                                <Col md={6}>
                                    <SelectInput
                                        id="letDuration"
                                        label="Let Duration Type"
                                        options={constants.LET_DURATION_OPTIONS}
                                        onChange={(selectedOption) => setLetDuration(selectedOption)}
                                        value={letDuration}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <SelectInput
                                        id="furnishedType"
                                        label="Furnished Type"
                                        options={constants.FURNISHING_OPTIONS}
                                        onChange={(selectedOption) => setFurnishType(selectedOption)}
                                        value={furnishType}
                                    />
                                </Col>

                                <Col md={6}>
                                    <SelectInput
                                        id="billsIncluded"
                                        label="Bills Included"
                                        options={constants.BILLS_INCLUDED_OPTIONS}
                                        onChange={(selectedOption) => setBillsIncluded(selectedOption)}
                                        value={billsIncluded}
                                    />
                                </Col>
                            </Row>
                            <h2>Property Availability</h2>  
                            <p className='form-subtitle'>Set the occupancy date for when the property will be available for tenants to move in.</p>
                            <Row>
                                <Col md={6}>
                                    <DateInput
                                        id="occupancyDate"
                                        label="Occupancy Date"
                                        value={occupancyDate}
                                        onChange={(e) => setOccupancyDate(e.target.value)}
                                    />
                                </Col>


                            </Row>
                            <h2>Preferences and Restrictions</h2>  
                            <p className='form-subtitle'>Define tenant preferences and any restrictions that apply to the rental agreement.</p>
                            <Row>
                                <Col md={12}>
                                    <CreatableSelectInput
                                        id="suitableFor"
                                        label="Suitable For"
                                        isMulti
                                        options={constants.SUITABLE_FOR_OPTIONS}
                                        onChange={(selectedOptions) => setSuitableFor(selectedOptions)}
                                        value={suitableFor}
                                        tooltipText="In addition to selecting a suitable tenant type from the list, you can type in the name of a suitable tenant type and press enter to add it to the list."
                                        tooltipId="suitable-for-tooltip"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <CreatableSelectInput
                                        id="restrictions"
                                        label="Restrictions"
                                        isMulti
                                        options={constants.RESTRICTIONS_OPTIONS}
                                        onChange={(selectedOptions) => setRestrictions(selectedOptions)}
                                        value={restrictions}
                                        tooltipText="In addition to selecting a restriction from the list, you can type in the name of a restriction and press enter to add it to the list."
                                        tooltipId="restrictions-tooltip"
                                    />
                                </Col>
                            </Row>
                            </div>
                            <Button variant="primary" type="submit" className="mt-3 me-2 continue-button">
                                Continue
                            </Button>
                            <Button variant="secondary" onClick={handleBack} className="mt-3 me-2 back-button">
                                Back
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </StandardPage>
    );
};

export default RentDetails;

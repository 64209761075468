// FeedbackModal.js  
import React, { useState } from 'react';  
import { Modal, Button } from 'react-bootstrap';  
  
const FeedbackModal = ({ show, onHide, onFeedbackSubmit, feedbackType }) => {  
    const [feedback, setFeedback] = useState('');  
    const feedbackMessage = feedbackType === 'like'  
        ? '🎉 What did you like?'  
        : '😕 What went wrong?';
  
    const handleSubmit = () => {  
        onFeedbackSubmit(feedback);  
        onHide();  
        setFeedback(''); // Clear feedback after submission  
    };  
  
    return (  
        <Modal show={show} onHide={onHide} centered className='feedbackModal'>  
            <Modal.Header closeButton>  
                <Modal.Title>Submit feedback to RealtyGen</Modal.Title>  
            </Modal.Header>  
            <Modal.Body>  
                <p>{feedbackMessage}</p>
                <textarea  
                    className="form-control"  
                    value={feedback}  
                    onChange={(e) => setFeedback(e.target.value)}  
                    placeholder="Your feedback..."  
                />  
            </Modal.Body>  
            <Modal.Footer>  
                <Button variant="primary" onClick={handleSubmit}>  
                    Submit  
                </Button>  
                <Button variant="secondary" onClick={onHide}>  
                    Cancel  
                </Button>  
            </Modal.Footer>  
        </Modal>  
    );  
};  
  
export default FeedbackModal;  

import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FeatureTile = ({ title, description, link, icon, available }) => {
  const cardContent = (
    <Card.Body className={!available ? "text-muted" : ""}>
      <Card.Title>{icon} {title}</Card.Title>
      <Card.Text>{description}</Card.Text>
      {!available && <div className="coming-soon">Coming Soon</div>}
    </Card.Body>
  );

  return available ? (
    <Link to={link} className="text-decoration-none">
      <Card className="feature-tile h-100">
        {cardContent}
      </Card>
    </Link>
  ) : (
    <Card className="feature-tile h-100 bg-light">
      {cardContent}
    </Card>
  );
};

export default FeatureTile;

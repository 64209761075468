import React from 'react';
import StandardPage from './StandardPage';
import { Row, Col, Image, Container } from 'react-bootstrap';
import '../../css/tiles.css'



const FeaturesPage = () => {
  const features = [
    {
      title: 'Instant Postcode Insights',
      description: 'Enter a postcode; get local facts. Neighbourhood, amenities, and more at your fingertips!',
      imageUrl: '/img/feature01.png',
    },
    {
      title: 'AI Image Analysis',
      description: 'Upload photos, receive property insights. Bedrooms, bathrooms, features—uncovered by AI!',
      imageUrl: '/img/feature02.png',
    },
    {
      title: 'Easy Detail Setup',
      description: 'Simplify your settings. Pre-filled options for EPC, Council Tax, and amenities. Edit with ease!',
      imageUrl: '/img/feature03.png',
    },
    {
      title: 'AI Listing Creation',
      description: 'Effortless listings, AI-powered. Preview, edit, and share your custom listing in moments.',
      imageUrl: '/img/feature05.png',
    },
    {
      title: 'AI Listing Review',
      description: 'Perfect your pitch with AI feedback. Clarity, appeal, accuracy—enhanced by intelligent analysis.',
      imageUrl: '/img/feature06.png',
    },
    {  
      title: 'Seamless Sharing Options',  
      description: 'Distribute your listings effortlessly. Copy with a click, or export PDFs to share with others.',  
      imageUrl: '/img/feature07.png',
  }  
  
  ];


  const renderFeatureTile = (feature) => (
    <Col md={4} sm={6} xs={12} className="g-4">
      <div className="feature-tile">
        <Image src={feature.imageUrl} alt={feature.title} className="feature-image" />
        <h3 className="feature-title">{feature.title}</h3>
        <p className="feature-description">{feature.description}</p>
      </div>
    </Col>
  );
  return (
    <StandardPage>
      <Container>
        <h1 className="mb-2">Listing Generator</h1>
        <p className="features-page-introduction">
          RealtyGen can help you create high-quality listing descriptions for your properties in minutes. Get all these features with RealtyGen.
        </p>
        <Row className="row-cols-1 row-cols-md-3 g-3">
          {features.map((feature, index) => renderFeatureTile(feature))}
        </Row>

        <Row className="call-to-action">
          <Col>
            <h2>Ready to get started?</h2>
            <p>Take advantage of these features and more by signing up today!</p>
            <a href="/preview">Sign up for the private preview</a>
          </Col>
        </Row>
      </Container>
    </StandardPage>
  );

};

export default FeaturesPage;  

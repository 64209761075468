// PropertyFeatures.js

// React components
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// UI components
import StandardPage from '../StandardPage';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import SelectInput from '../../ui/SelectInput';
import NumberInput from '../../ui/NumberInput';
import CreatableSelectInput from '../../ui/CreatableSelectInput';
import { FaMagic } from 'react-icons/fa';

// Data
import * as constants from '../../data/constants';

const PropertyFeatures = () => {
    // Get the navigate and location hooks
    const navigate = useNavigate();
    const location = useLocation();
    const propertyListingData = location.state || {};

    // Set the initial state based on the propertyListingData
    const [propertyType, setPropertyType] = useState(propertyListingData.propertyFeatures?.propertyType || null);
    const [propertyCondition, setPropertyCondition] = useState(propertyListingData.propertyFeatures?.propertyCondition || null);
    const [bedrooms, setBedrooms] = useState(propertyListingData.propertyFeatures?.bedrooms || null);
    const [bathrooms, setBathrooms] = useState(propertyListingData.propertyFeatures?.bathrooms || null);
    const [floorArea, setFloorArea] = useState(propertyListingData.propertyFeatures?.floorArea || null);
    const [epcRating, setEpcRating] = useState(propertyListingData.propertyFeatures?.epcRating || null);
    const [councilTax, setCouncilTax] = useState(propertyListingData.propertyFeatures?.councilTax || null);
    const [propertyFeatures, setPropertyFeatures] = useState(propertyListingData.propertyFeatures?.propertyFeatures || []);
    const [buildingAmenities, setBuildingAmenities] = useState(propertyListingData.propertyFeatures?.buildingAmenities || []);

    // Handle the back button
    const handleBack = () => {
        // Navigate back to the PropertyImages page, passing along the current state,
        // including any changes made on the PropertyFeatures page.
        navigate('/listing/images', {
            state: {
                ...propertyListingData,
                propertyFeatures: {
                    propertyType,
                    propertyCondition,
                    bedrooms,
                    bathrooms,
                    floorArea,
                    councilTax,
                    epcRating,
                    propertyFeatures,
                    buildingAmenities
                }
            }
        });
    };

    // Handle the form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Navigate to the next page with the form data
        navigate('/listing/rent', {
            state: {
                ...propertyListingData,
                propertyFeatures: {
                    propertyType,
                    propertyCondition,
                    bedrooms,
                    bathrooms,
                    floorArea,
                    councilTax,
                    epcRating,
                    propertyFeatures,
                    buildingAmenities
                }
            }
        });
    };

    return (
        <StandardPage>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                        <h1 className="mb-2">Property Features</h1>
                        <p className="mb-2">Please enter details about the property's features and amenities.</p>
                        <div className="disclaimer mb-4">
                            Fields on this form indicated by <FaMagic className="icon-magic" /> may have been pre-populated by AI, using the property photos you provided. Please review all AI-generated content carefully and make any necessary corrections to ensure accuracy.
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <div className='property-listing-form'>
                                <h2>Property Specifications</h2>
                                <p className='form-subtitle'>Provide essential details about the property to help potential buyers or renters understand what is being offered.</p>
                                <Row>
                                    <Col md={6}>
                                        <SelectInput
                                            id="propertyType"
                                            label={<>Property Type<span className='required-input'>*</span></>}
                                            options={constants.PROPERTY_TYPE_OPTIONS}
                                            required
                                            onChange={selectedOption => setPropertyType(selectedOption)}
                                            value={propertyType}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SelectInput
                                            id="propertyCondition"
                                            label="Property Condition"
                                            options={constants.PROPERTY_CONDITION_OPTIONS}
                                            onChange={selectedOption => setPropertyCondition(selectedOption)}
                                            value={propertyCondition}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <SelectInput
                                            id="bedrooms"
                                            label={<><FaMagic className="icon-magic" /> No. of Bedrooms<span className='required-input'>*</span></>}
                                            options={constants.BEDROOM_OPTIONS}
                                            required
                                            onChange={selectedOption => setBedrooms(selectedOption)}
                                            value={bedrooms}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SelectInput
                                            id="bathrooms"
                                            label={<><FaMagic className="icon-magic" /> No. of Bathrooms<span className='required-input'>*</span></>}
                                            options={constants.BATHROOM_OPTIONS}
                                            required
                                            onChange={selectedOption => setBathrooms(selectedOption)}
                                            value={bathrooms}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <SelectInput
                                            id="epcRating"
                                            label="EPC Rating"
                                            options={constants.EPC_RATING_OPTIONS}
                                            onChange={selectedOption => setEpcRating(selectedOption)}
                                            value={epcRating}
                                            tooltipText="EPC stands for Energy Performance Certificate. The EPC rating is a measure of the energy efficiency of a property."
                                            tooltipId="epc-tooltip"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <SelectInput
                                            id="councilTax"
                                            label="Council Tax Band"
                                            options={constants.COUNCIL_TAX_OPTIONS}
                                            onChange={selectedOption => setCouncilTax(selectedOption)}
                                            value={councilTax}
                                            tooltipText="Council tax is a tax on domestic property set by local authorities to help pay for local services."
                                            tooltipId="council-tax-tooltip"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <NumberInput
                                            id="floorArea"
                                            label={<><FaMagic className="icon-magic" /> Floor Area</>}
                                            afterText="sq. ft."
                                            onChange={event => setFloorArea(event.target.value)}
                                            value={floorArea}
                                            tooltipText="The floor area is the total area of the property in square feet."
                                            tooltipId="floor-area-tooltip"
                                        />
                                    </Col>
                                </Row>
                                <h2>Property Features & Amenities</h2>
                                <p className='form-subtitle'>Highlight the unique attributes and conveniences that make this property stand out.</p>
                                <Row>
                                    <Col md={12}>
                                        <CreatableSelectInput
                                            id="propertyFeatures"
                                            label={<><FaMagic className="icon-magic" /> Property Features</>}
                                            isMulti
                                            options={constants.PROPERTY_FEATURES_OPTIONS}
                                            value={propertyFeatures}
                                            onChange={selectedOptions => setPropertyFeatures(selectedOptions)}
                                            tooltipText="In addition to selecting features from the list, you can type in the name of a feature and press enter to add it to the list."
                                            tooltipId="feature-tooltip"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <CreatableSelectInput
                                            id="buildingAmenities"
                                            label="Building Amenities"
                                            isMulti
                                            options={constants.BUILDING_AMENITIES_OPTIONS}
                                            value={buildingAmenities}
                                            onChange={selectedOptions => setBuildingAmenities(selectedOptions)}
                                            tooltipText="In addition to selecting building amenities from the list, you can type in the name of a building amenity and press enter to add it to the list."
                                            tooltipId="amenity-tooltip"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col xs={12} className="d-flex mt-3">
                                    <Button variant="primary" type="submit" className="me-2 continue-button">
                                        Continue
                                    </Button>
                                    <Button variant="secondary" onClick={handleBack} className="back-button">
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </StandardPage>
    );
};

export default PropertyFeatures;

// FetchDraft.js
import React, { useState, useEffect } from 'react';
import { Spinner, Button, Form, ListGroup } from 'react-bootstrap';
import { FaRegTrashAlt, FaPlus, FaCircle } from 'react-icons/fa';

const FetchDraft = ({ propertyListingData, onDraftFetched }) => {

    const [draftContent, setDraftContent] = useState({
        keyFeatures: [],
        propertyDescription: ''
    });


    const [loadingStatus, setLoadingStatus] = useState('loading');

    // Define the simplifyLabels function  
    const simplifyLabels = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (Array.isArray(value) && value.length > 0 && value[0].hasOwnProperty('value') && value[0].hasOwnProperty('label')) {
                    obj[key] = value.map(item => item.label);
                } else if (typeof value === 'object' && value !== null && value.hasOwnProperty('value') && value.hasOwnProperty('label')) {
                    obj[key] = value.label;
                } else if (typeof value === 'object' && value !== null) {
                    simplifyLabels(value);
                }
            }
        }
    };

    const handleFeatureChange = (index, value) => {
        const updatedFeatures = [...draftContent.keyFeatures];
        updatedFeatures[index] = value;
        const updatedDraftContent = {
            ...draftContent,
            keyFeatures: updatedFeatures,
        };
        setDraftContent(updatedDraftContent);
        onDraftFetched(updatedDraftContent);
    };

    const handleRemoveFeature = (index) => {
        const updatedFeatures = draftContent.keyFeatures.filter((_, i) => i !== index);
        const updatedDraftContent = {
            ...draftContent,
            keyFeatures: updatedFeatures,
        };
        setDraftContent(updatedDraftContent);
        onDraftFetched(updatedDraftContent); // Notify parent component of change
    };

    const handleAddFeature = () => {
        const updatedFeatures = [...draftContent.keyFeatures, '']; // Add an empty string as a new feature
        const updatedDraftContent = {
            ...draftContent,
            keyFeatures: updatedFeatures,
        };
        setDraftContent(updatedDraftContent);
        onDraftFetched(updatedDraftContent); // Notify parent component of change
    };

    useEffect(() => {
        // Immediately return if draftContent is already set  
        if (draftContent.keyFeatures.length !== 0 || draftContent.propertyDescription !== '') return;

        const fetchDraftData = async () => {
            const draftPayload = JSON.parse(JSON.stringify({
                propertyLocation: propertyListingData.propertyLocation,
                propertyFeatures: propertyListingData.propertyFeatures,
                rentDetails: propertyListingData.rentDetails,
            }));

            // Call simplifyLabels to process the draftPayload  
            simplifyLabels(draftPayload);

            try {
                const draftResponse = await fetch('/api/generateListing', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(draftPayload)
                });
                if (!draftResponse.ok) throw new Error('Failed to generate draft');
                let text = await draftResponse.text(); // Get the response as text first      

                // Check if the response has backticks and 'json' at the start      
                if (text.startsWith('```json') && text.endsWith('```')) {
                    // Remove the backticks and 'json' from start and end to sanitize      
                    text = text.substring(7, text.length - 3).trim();
                }

                // Now, we can safely parse the sanitized text as JSON      
                const data = JSON.parse(text);
                const fullDescription = [
                    data.propertyDescription.introduction,
                    data.propertyDescription.detailedBreakdown,
                    data.propertyDescription.callToAction
                ].filter(part => part).join('\n\n'); // Filter out empty parts and join with double newlines  

                // Ensure that we are updating the state with the constructed fullDescription, not the raw data  
                setDraftContent({
                    keyFeatures: data.keyFeatures,
                    propertyDescription: fullDescription,
                });
                setLoadingStatus('done');

                // Call the callback function with the constructed data instead of the raw data  
                onDraftFetched({
                    keyFeatures: data.keyFeatures,
                    propertyDescription: fullDescription,
                });
            } catch (error) {
                console.error("Error fetching draft:", error);
                setLoadingStatus('error');
            }
        };

        fetchDraftData();
    }, [propertyListingData]);

    if (loadingStatus === 'loading') {
        return <Spinner animation="border" />;
    }

    if (loadingStatus === 'error') {
        return <p>Error fetching draft content.</p>;
    }

    // Render the editable draft content or return null if there is no content to render  
    return draftContent ? (
        <>
            <h2>Key Features</h2>
            <ListGroup as="ul">
                {draftContent.keyFeatures.map((feature, index) => (
                    <ListGroup.Item as="li" key={index} className="listing-feature feature-item d-flex align-items-center">
                        <FaCircle className="feature-icon me-2" /> {/* Add this line */}
                        <Form.Control
                            type="text"
                            value={feature}
                            onChange={(e) => handleFeatureChange(index, e.target.value)}
                            className="me-2"
                        />
                        <Button className="trash-button" size="sm" onClick={() => handleRemoveFeature(index)}>
                            <FaRegTrashAlt />
                        </Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Button className="plus-button mt-2" onClick={handleAddFeature} aria-label="Add feature">
                <FaPlus /> Add Feature
            </Button>
            <h2 className="mt-4">Property Description</h2>
            <Form.Group className="mb-3">
                <Form.Control
                    as="textarea"
                    rows={10}
                    value={draftContent.propertyDescription}
                    onChange={(e) => {
                        setDraftContent({
                            ...draftContent,
                            propertyDescription: e.target.value,
                        });
                        onDraftFetched({
                            ...draftContent,
                            propertyDescription: e.target.value,
                        });
                    }}
                />
            </Form.Group>
        </>
    ) : null;
};

export default FetchDraft;

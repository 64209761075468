// PricingPage.js  
import React from 'react';  
import StandardPage from './StandardPage';  
import { Row, Col, Container } from 'react-bootstrap';  
import '../../css/pricing.css';  
  
const PricingPage = () => {  
  const pricingPlans = [  
    {  
      title: 'Private Preview',  
      description: 'Exclusive access to our private preview phase.',  
      cost: '$0',  
      frequency: 'per month',  
      features: [  
        'Generate real estate listings',  
        'Edit and share listings',  
        'Intelligent postcode-based local amenities API',  
        'Generative AI models for content creation',  
      ],  
    },  
    // ... future plans will go here  
  ];  
  
  const renderPricingPlan = (plan) => (  
    <Col md={4} sm={6} xs={12} className="g-4">  
      <div className="pricing-tile">  
        <div className="pricing-header">  
          <h3 className="pricing-title">{plan.title}</h3>  
          <p className="pricing-cost">{plan.cost}<span className="pricing-frequency">/{plan.frequency}</span></p>  
        </div>  
        <p className="pricing-description">{plan.description}</p>  
        <ul className="pricing-features">  
          {plan.features.map((feature, index) => (  
            <li key={index} className="feature">  
              <span className="tick">&#10003;</span> {feature}  
            </li>  
          ))}  
        </ul>  
      </div>  
    </Col>  
  );  
  
  return (  
    <StandardPage>  
      <Container>  
        <h1 className="mb-4">Pricing</h1>  
        <Row className="row-cols-1 row-cols-md-3 g-3">  
          {pricingPlans.map((plan, index) => renderPricingPlan(plan))}  
        </Row>  
      </Container>  
    </StandardPage>  
  );  
};  
  
export default PricingPage;  

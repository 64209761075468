// PropertyImages.js

// React components
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// UI components
import StandardPage from '../StandardPage';
import { Button, Alert, Row, Col, Container, ProgressBar } from 'react-bootstrap';
import { FaTimes, FaMagic } from 'react-icons/fa';

const PropertyImages = () => {
    // Get the navigate and location hooks
    const navigate = useNavigate();
    const location = useLocation();
    const propertyListingData = location.state || {};

    // Set the initial state based on the propertyListingData
    const [selectedFiles, setSelectedFiles] = useState(propertyListingData.propertyImages?.selectedFiles || []);
    const [base64Images, setBase64Images] = useState(propertyListingData.propertyImages?.base64Images || []);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [bedrooms, setBedrooms] = useState(propertyListingData.propertyFeatures?.bedrooms || null);
    const [bathrooms, setBathrooms] = useState(propertyListingData.propertyFeatures?.bathrooms || null);
    const [floorArea, setFloorArea] = useState(propertyListingData.propertyFeatures?.floorArea || null);
    const [propertyFeatures, setPropertyFeatures] = useState(propertyListingData.propertyFeatures?.propertyFeatures || []);

    // Set the initial state for the upload complete flag
    const [isUploadComplete, setIsUploadComplete] = useState(false);

    // Store the blob URLs in the state  
    const [imagePreviews, setImagePreviews] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        // Generate new blob URLs for the selectedFiles and store them in the state
        const newImagePreviews = selectedFiles.map(file =>
            URL.createObjectURL(file)
        );
        setImagePreviews(newImagePreviews);

        // Revoke blob URLs when the component unmounts
        return () => {
            newImagePreviews.forEach(blobUrl => URL.revokeObjectURL(blobUrl));
        };
    }, [selectedFiles]); // Depend on selectedFiles so that it updates when files are added or removed

    useEffect(() => {
        if (isUploadComplete) {
            navigateToFeaturesPage();
        }
    }, [isUploadComplete]);

    // Handle file selection  
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        // Limit the number of selected files to 10  
        const newFiles = files.slice(0, 10 - selectedFiles.length);
        if (selectedFiles.length + files.length > 10) {
            alert("You can only upload up to 10 images.");
        }
        setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
        convertToBase64(newFiles);

        // Clear bedrooms, bathrooms, and property features whenever the image list is modified  
        setBedrooms(null);
        setBathrooms(null);
        setFloorArea(null);
        setPropertyFeatures([]);

        // Reset the file input  
        event.target.value = '';
    };

    // Convert the selected files to base64 strings
    const convertToBase64 = (files) => {
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = document.createElement('img');
                img.src = e.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    const MAX_WIDTH = 512;
                    const MAX_HEIGHT = 512;
                    let width = img.width;
                    let height = img.height;

                    // Calculate the new dimensions maintaining the aspect ratio  
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    // Set the canvas dimensions to the new dimensions  
                    canvas.width = width;
                    canvas.height = height;
                    // Draw the resized image on the canvas  
                    ctx.drawImage(img, 0, 0, width, height);
                    // Convert the canvas to a data URL and strip off the data url prefix to get just the base64 string  
                    const base64String = canvas.toDataURL('image/jpeg').replace('data:image/jpeg;base64,', '');
                    setBase64Images(prevImages => [...prevImages, base64String]);
                };
            };
            reader.readAsDataURL(file);
        });
    };

    // Remove a file from the selected files list  
    const removeFile = (index) => {
        // Revoke the blob URL for the removed file
        URL.revokeObjectURL(imagePreviews[index]);

        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setBase64Images(prevImages => prevImages.filter((_, i) => i !== index));
        setImagePreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));

        // Clear bedrooms, bathrooms, and property features whenever the image list is modified
        setBedrooms(null);
        setBathrooms(null);
        setFloorArea(null);
        setPropertyFeatures([]);

        // Reset the file input if necessary
        fileInputRef.current.value = '';
    };

    // Render the thumbnails for the selected files  
    const renderThumbnails = () => {
        return imagePreviews.map((blobUrl, index) => (
            <Col key={index} xs={6} md={4} lg={3} className="mb-3 position-relative">
                <img
                    src={blobUrl}
                    alt={`Property ${index}`}
                    className="img-thumbnail"
                />
                <Button
                    variant="secondary"
                    className="removeImageButton"
                    style={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={() => removeFile(index)}
                    disabled={isUploading}
                >
                    <FaTimes />
                </Button>
            </Col>
        ));
    };

    // Handle file drag and drop
    const handleDrop = (event) => {
        event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
        if (selectedFiles.length >= 10) {
            alert("You can only upload up to 10 images.");
            return;
        }

        const droppedFiles = Array.from(event.dataTransfer.files); // Convert FileList to Array
        const imageFiles = droppedFiles.filter((file) => file.type.startsWith('image/')); // Only accept image files

        const newFiles = imageFiles.slice(0, 10 - selectedFiles.length);
        setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
        convertToBase64(newFiles);
    };

    // Fetch property details from GPT-4V
    const fetchPropertyDetails = async () => {
        if (propertyFeatures.length > 0) {
            navigateToFeaturesPage();
            return;
        }

        setIsUploading(true);
        setUploadProgress(0); // Start from 0 progress

        // Initialize a counter to track elapsed time
        let elapsedTime = 0;
        // Set the duration for the progress animation (e.g., 45 seconds)
        const animationDuration = 10000; // 45 seconds in milliseconds
        // Set the interval time for updating progress (e.g., every 1 second)
        const intervalTime = 1000; // 1 second in milliseconds

        // Calculate how much to increment progress on each interval tick
        const progressIncrement = (90 / (animationDuration / intervalTime)); // 90% divided by the number of ticks

        // Use a setInterval to increment progress
        const progressInterval = setInterval(() => {
            elapsedTime += intervalTime;
            setUploadProgress(prevProgress => {
                // Calculate the new progress, but do not exceed 90%
                const newProgress = Math.min(prevProgress + progressIncrement, 90);
                return newProgress;
            });

            // If the animation duration has been reached, stop the interval
            if (elapsedTime >= animationDuration) {
                clearInterval(progressInterval);
            }
        }, intervalTime);

        try {
            const response = await fetch('/api/getPropertyFeatures', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ images: base64Images }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setBedrooms({ value: data.numberOfBedrooms, label: data.numberOfBedrooms });
            setBathrooms({ value: data.numberOfBathrooms, label: data.numberOfBathrooms });
            setFloorArea(data.floorArea);
            setPropertyFeatures(data.propertyFeatures);

            // Set progress to 100%, and indicate that the upload is complete  
            setUploadProgress(100);
            setIsUploading(false);
            setIsUploadComplete(true); // This will trigger the effect to navigate  
        } catch (error) {
            console.error('Error fetching property details:', error);
            setIsUploading(false);
        } finally {
            clearInterval(progressInterval);
        }
    };

    // Navigate to the features page
    const navigateToFeaturesPage = () => {
        // Navigate to the features page with all the necessary data  
        navigate('/listing/features', {
            state: {
                ...propertyListingData,
                propertyFeatures: {
                    ...propertyListingData.propertyFeatures, // Spread existing features first  
                    bedrooms,
                    bathrooms,
                    floorArea,
                    propertyFeatures
                },
                propertyImages: {
                    selectedFiles,
                    base64Images
                }
            },
        });
    };

    // Navigate back to the location page
    const handleBack = () => {
        // Navigate back to the previous page, passing along the current propertyListingData
        navigate('/listing/location', {
            state: {
                ...propertyListingData,
                propertyFeatures: {
                    bedrooms,
                    bathrooms,
                    floorArea,
                    propertyFeatures
                },
                propertyImages: {
                    selectedFiles,
                    base64Images
                }
            },
        });
    };

    // Handle the next button click
    const handleNext = async () => {
        if (base64Images.length > 0) {
            await fetchPropertyDetails();
        } else {
            navigateToFeaturesPage();
        }
    };

    return (
        <StandardPage>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                        <h1 className="mb-2">Property Images</h1>
                        <div className="disclaimer mb-4">
                            <FaMagic className="icon-magic" /> You have the option to upload photos of the property. If you do, our AI vision system will scan the photos and attempt to identify the number of bedrooms, the number of bathrooms, and various property features. This will help us generate a more detailed and attractive property listing description.
                            <br></br>
                            <br></br>
                            Note: If you prefer not to upload photos, you can skip this step by clicking continue and specifying property features manually.
                        </div>
                        {!isUploading && ( // This block will be hidden during the uploading process  
                            <>
                                <p className="mb-2">Upload photos that showcase key features of the property.</p>
                                <div className="mb-3">
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        id="file-upload"
                                        style={{ display: 'none' }}
                                        disabled={selectedFiles.length >= 10}
                                    />
                                    <label
                                        htmlFor="file-upload"
                                        className={`btn btn-outline-primary btn-block image-upload ${selectedFiles.length >= 10 ? "disabled" : ""}`}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={handleDrop}
                                        // Disable click when 10 images are selected  
                                        onClick={selectedFiles.length >= 10 ? (e) => e.preventDefault() : undefined}
                                    >
                                        {selectedFiles.length < 10 ? "Drag and drop images here or click to browse" : "Maximum of 10 images reached"}
                                    </label>
                                    {selectedFiles.length > 0 && (
                                        <Alert variant="success" className="mt-3">
                                            {selectedFiles.length} image(s) selected.
                                        </Alert>
                                    )}
                                </div>
                            </>
                        )}
                        {isUploading && (
                            <>
                                <p className="mb-2">Analyzing images to identify property features. Please wait, this may take a few moments.</p>
                                <ProgressBar
                                    now={uploadProgress}
                                    label={`${uploadProgress}%`}
                                    striped
                                    animated
                                    className="my-3"
                                />
                            </>
                        )}
                        <Row>{renderThumbnails()}</Row>
                        <Row>
                            <Col xs={12}>
                                <Button
                                    variant="primary"
                                    onClick={handleNext}
                                    className='me-2 continue-button'
                                    disabled={isUploading} // Disable the Continue button when uploading  
                                >
                                    Continue
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleBack}
                                    className="back-button"
                                    disabled={isUploading} // Disable the Back button when uploading  
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StandardPage>
    );
};

export default PropertyImages;

import React, { useState } from 'react';
import StandardPage from './StandardPage';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import AlertComponent from '../ui/AlertComponent'; // Import AlertComponent  

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [alert, setAlert] = useState({ show: false, message: '', type: '' }); // Alert state includes show property  
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to show the alert  
  const showAlert = (message, type) => {
    setAlert({ show: true, message, type });
  };

  // Function to handle closing the alert  
  const handleCloseAlert = () => {
    setAlert(prevAlert => ({ ...prevAlert, show: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const apiEndpoint = '/api/sendMail';

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        showAlert('Your message has been sent successfully!', 'success');
      } else {
        showAlert('Failed to send message. Please try again.', 'danger');
      }
    } catch (error) {
      showAlert('Failed to send message. Please try again later.', 'danger');
      console.error('There was an error!', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StandardPage title="Contact Us">
      <div className="contact-page-content">
        <Row>
          <p>We'd love to hear from you! Please fill out the form below and we will get back to you as soon as possible.</p>
          <Col md={6}>
            {/* Render AlertComponent */}
            <AlertComponent
              show={alert.show}
              message={alert.message}
              type={alert.type}
              onClose={handleCloseAlert}
            />

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={3}
                  placeholder="Enter your message"
                  required
                />
              </Form.Group>

              <Button className="contact-submit-button" variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="sr-only">Sending...</span>
                  </>
                ) : (
                  'Submit'
                )}
              </Button>
            </Form>
          </Col>
          <Col md={6}>
            {/* Other content or imagery could be placed here */}
          </Col>
        </Row>
      </div>
    </StandardPage>
  );
};

export default ContactPage;  

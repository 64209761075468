// src/components/data/features.js

export const features = [
    {
      title: "Property Listing",
      description: "Create compelling property listings with ease.",
      link: "/listing/location",
      icon: "🏠",
      available: true
    },
    {
      title: "Property Brochure",
      description: "Design stunning brochures for your property listings.",
      link: "/property-brochure",
      icon: "📑",
      available: false
    },
    {
      title: "Marketing Newsletter",
      description: "Craft effective newsletters to engage your audience.",
      link: "/marketing-newsletter",
      icon: "📰",
      available: false
    },
    {
      title: "Email Response",
      description: "Respond to inquiries with professional, well-crafted emails.",
      link: "/email-response",
      icon: "📧",
      available: false
    },
    {
      title: "Contract Template",
      description: "Generate legal documents and contracts with templates tailored to your needs.",
      link: "/contract-template",
      icon: "📄",
      available: false
    },
    {
      title: "Market Report",
      description: "Get up-to-date market reports to make informed decisions.",
      link: "/market-report",
      icon: "📈",
      available: false
    }
  ];
  
  export default features;
  
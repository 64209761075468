// FetchEvaluation.js
import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../ui/SkeletonLoader';

// Add the categorizeScore function  
const categorizeScore = (score) => {  
    if (score >= 0 && score <= 5) {  
        return 'Needs Improvement';  
    } else if (score >= 6 && score <= 10) {  
        return 'Average';  
    } else if (score >= 11 && score <= 15) {  
        return 'Good';  
    } else if (score >= 16 && score <= 20) {  
        return 'Excellent';  
    } else {  
        return 'Unknown'; // In case the score is out of the expected range  
    }  
}; 

const FetchEvaluation = ({ currentDraftContent, lastEvaluatedContent, setLastEvaluatedContent, evaluationResults, setEvaluationResults }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvaluationResults = async () => {
            // Only fetch new results if the content has changed
            if (currentDraftContent === lastEvaluatedContent || evaluationResults) {
                return;
            }

            setLoading(true);
            setError(null);
            setLastEvaluatedContent(currentDraftContent);

            try {
                const response = await fetch('/api/evaluateListing', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ listingDescription: currentDraftContent })
                });

                if (!response.ok) throw new Error('Failed to fetch evaluation results');
                const data = await response.json();
                setEvaluationResults(data);
            } catch (error) {
                console.error("Error fetching evaluation results:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchEvaluationResults();
    }, [currentDraftContent, lastEvaluatedContent, setLastEvaluatedContent, setEvaluationResults]);

    if (loading || !evaluationResults) {
        return (
            <>
                <h2>Generating Scorecard</h2>
                <div>
                    {Array.from({ length: 3 }, (_, index) => ( // Assuming there will be 3 criteria  
                        <div key={index}>
                            <h3><SkeletonLoader height={20} /></h3>
                            <p><SkeletonLoader height={10} /></p>
                            <p><SkeletonLoader height={10} /></p>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    if (error) {
        return <p>Error fetching evaluation results.</p>;
    }

    const getBadgeClass = (score) => {
        if (score <= 0) {
            return 'bg-danger';
        } else if (score === 1) {
            return 'bg-warning text-dark';
        } else if (score >= 2) {
            return 'bg-success';
        } else {
            return ''; // Default case, no badge
        }
    };

    const category = evaluationResults ? categorizeScore(evaluationResults.total_score) : 'Calculating...';  

    return (
        <>
            <div className="fade-in">
                <h2>Total Score: {evaluationResults.total_score} ({category})</h2>
                <div>
                    {Object.entries(evaluationResults.evaluationResults).map(([criteria, result]) => (
                        <div key={criteria}>
                            <h3>{criteria}</h3>
                            <p>{result.tip ? result.tip : ''}</p>
                            <p>Score: <span className={`badge rounded-pill ${getBadgeClass(result.score_value)}`}> {result.score_label}</span></p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FetchEvaluation;

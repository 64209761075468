import React from 'react';
import Select from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa'; // Import FaInfoCircle

const SelectInput = ({
  className,
  id,
  label,
  options,
  onChange,
  required,
  value,
  tooltipText, // Add tooltipText prop
  tooltipId // Add tooltipId prop
}) => (
  <div className={className}>
    <label htmlFor={id} className="col-form-label">
      {label}
      {tooltipText && ( // Check if tooltip text is provided
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={tooltipId || `tooltip-${id}`}>
              {tooltipText}
            </Tooltip>
          }
        >
          <span>
            <FaInfoCircle className="info-icon ml-2" style={{ cursor: 'pointer' }} />
          </span>
        </OverlayTrigger>
      )}
    </label>
    <Select
      className='react-select-custom'
      id={id}
      options={options}
      onChange={onChange}
      required={required}
      value={value}
      menuPlacement="auto"
    />
  </div>
);

export default SelectInput;

// usePostcodeValidation.js
import { useState } from 'react';

const usePostcodeValidation = () => {
    const [isPostcodeValid, setPostcodeValid] = useState(true);
    const [neighbourhood, setNeighbourhood] = useState('');
    const [neighbourhoodLoading, setNeighbourhoodLoading] = useState(false); // Added state for loading
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);

    const validateAndFetchNeighbourhoodData = async (postcode, callback) => {
        setNeighbourhoodLoading(true); // Start loading
        let postcodeIsValid = false;

        try {
            const validationResponse = await fetch(`https://api.postcodes.io/postcodes/${encodeURIComponent(postcode)}/validate`);
            const validationData = await validationResponse.json();
            postcodeIsValid = validationData.result;

            if (postcodeIsValid) {
                const dataResponse = await fetch(`https://api.postcodes.io/postcodes/${encodeURIComponent(postcode)}`);
                const data = await dataResponse.json();
                if (data.status === 200 && data.result) {
                    setNeighbourhood(`${data.result.admin_ward}, ${data.result.admin_district}`);
                    setLongitude(data.result.longitude);
                    setLatitude(data.result.latitude);
                } else {
                    postcodeIsValid = false;
                }
                if (callback) {
                    callback(data.result.latitude, data.result.longitude)
                }
            }
        } catch (error) {
            console.error('Error fetching neighbourhood data:', error);
            postcodeIsValid = false;
        }

        setPostcodeValid(postcodeIsValid);
        setNeighbourhoodLoading(false); // End loading
    };

    return {
        isPostcodeValid,
        neighbourhood,
        neighbourhoodLoading, // Expose the loading state
        longitude,
        latitude,
        setNeighbourhood,
        validateAndFetchNeighbourhoodData,
    };
};

export default usePostcodeValidation;

import React, { useState, useEffect } from 'react';
import StandardPage from './StandardPage';

const AccountPage = () => {
  const [username, setUsername] = useState('');
  const [planStatus, setPlanStatus] = useState('Inactive');

  useEffect(() => {
    fetch('/.auth/me')
      .then(response => response.json())
      .then(data => {
        const clientPrincipal = data.clientPrincipal;
        if (clientPrincipal) {
          setUsername(clientPrincipal.userDetails);
          const isActive = clientPrincipal.userRoles.includes('active');
          setPlanStatus(isActive ? 'Active' : 'Inactive');
        }
      })
      .catch(error => {
        console.error('Error fetching user details:', error);
      });
  }, []);

  return (
    <StandardPage title="Account">
      <div className="account-details">
            <div className="row">
              <div className='col-md-2'><strong>Username:</strong></div>
              <div className='col-md-10'>{username}</div>
            </div>
            <div className="row">
              <div className='col-md-2'><strong>Plan Status:</strong></div>
              <div className='col-md-10'>{planStatus}</div>
            </div>
          </div>
    </StandardPage>
  );
};

export default AccountPage;

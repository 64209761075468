import React from 'react';
import NavBar from '../ui/NavBar';

const StandardPage = ({ children, title }) => (
  <div className="standard-page">
    <NavBar />
    <div className="standard-page-content">
      <h1>{title}</h1>
      {children}
    </div>
  </div>
);

export default StandardPage;

// fetchAmenityData.js
const fetchAmenity = async (url, mapFunc) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.map(mapFunc);
    } catch (error) {
      console.error('Error fetching amenity data:', error);
      return [];
    }
  };
  
  export const getNearestSchools = (latitude, longitude) => {
    return fetchAmenity(`/api/getNearestSchools?lat=${latitude}&lon=${longitude}`, school => ({
      value: school.urn,
      label: `${school.name} (${school.distance})`,
    }));
  };
  
  export const getNearestStations = (latitude, longitude) => {
    return fetchAmenity(`/api/getNearestStations?lat=${latitude}&lon=${longitude}`, station => ({
      value: station.stationId,
      label: `${station.station_name} (${station.distance_in_miles.toFixed(1)} miles)`,
    }));
  };
  
  export const getNearestRestaurants = (latitude, longitude) => {
    return fetchAmenity(`/api/getNearestPlaces?lat=${latitude}&lon=${longitude}&categories=100-1000-0001,200-2000-0011`, restaurant => ({
      value: restaurant.title,
      label: `${restaurant.title} (${restaurant.distance_in_miles.toFixed(1)} miles)`,
    }));
  };
  
  export const getNearestParks = (latitude, longitude) => {
    return fetchAmenity(`/api/getNearestPlaces?lat=${latitude}&lon=${longitude}&categories=550-5510-0202`, park => ({
      value: park.title,
      label: `${park.title} (${park.distance_in_miles.toFixed(1)} miles)`,
    }));
  };
  
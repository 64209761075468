// LandingPage.js    
import React, { useState, useEffect } from 'react';  
import { Row, Col, Container } from 'react-bootstrap';  
import NavBar from '../ui/NavBar';  
import FeatureTile from '../ui/FeatureTile';  
import { features } from '../data/features';  
import AlertComponent from '../ui/AlertComponent';  
  
const LandingPage = () => {  
  const [isAuthenticated, setIsAuthenticated] = useState(false);  
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });  
  
  useEffect(() => {  
    fetch('/.auth/me')  
      .then((response) => response.json())  
      .then((data) => {  
        const { clientPrincipal } = data;  
        setIsAuthenticated(!!clientPrincipal);  
      })  
      .catch((error) => {  
        console.error('Error fetching auth details', error);  
        setIsAuthenticated(false);  
      });  
  }, []);  
  
  const handleCloseAlert = () => {  
    setAlert(prevAlert => ({ ...prevAlert, show: false }));  
  };  
  
  return (  
    <div className="landing-page">  
      <NavBar />  
      <div className="landing-page-content d-flex align-items-center">  
        {isAuthenticated ? (  
          <div>  
            <h1 className="features-heading">Our Features</h1>  
            <p className="features-description">Explore the range of tools we offer to enhance your real estate business.</p>  
            <div className="features-grid">  
              {features.map((feature) => (  
                <FeatureTile  
                  key={feature.title}  
                  title={feature.title}  
                  description={feature.description}  
                  link={feature.link}  
                  icon={feature.icon}  
                  available={feature.available}  
                />  
              ))}  
            </div>  
          </div>  
        ) : (  
          <Container className="my-4">  
            <Row className="justify-content-center">  
              <Col xs={12} lg={8}>  
                <AlertComponent show={alert.show} message={alert.message} type={alert.type} onClose={handleCloseAlert} />  
                <div className="hero-heading">Revolutionise Your Listings with AI-Powered Precision</div>  
                <p className="sub-headline">Join RealtyGen's exclusive preview and elevate your real estate game with cutting-edge AI tools. Generate rental listings faster, captivate clients, and stay ahead in the market.</p>  
                <a href="/preview" className="btn btn-primary w-100"> {/* Set width to 100% */}  
                  Sign Up Now  
                </a>  
              </Col>  
            </Row>  
          </Container>  
        )}  
      </div>  
    </div>  
  );  
};  
  
export default LandingPage;  

import React from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa'; // Import FaInfoCircle

const TextInput = ({
  className,
  id,
  label,
  required,
  onChange,
  onBlur,
  onFocus,
  value,
  isInvalid,
  errorMessage,
  disabled,
  tooltipText,
  tooltipId
}) => (
  <div className={className}>
    <label htmlFor={id} className="col-form-label">  
      {label}  
      {tooltipText && (  
        <OverlayTrigger  
          placement="top"  
          overlay={  
            <Tooltip id={tooltipId || `tooltip-${id}`}>  
              {tooltipText}  
            </Tooltip>  
          }  
        >  
          <span>  
            <FaInfoCircle className="info-icon ml-2" style={{ cursor: 'pointer' }} />  
          </span>  
        </OverlayTrigger>  
      )}  
    </label>  
    <input
      type="text"
      className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
      id={id}
      value={value}
      required={required}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
    {isInvalid && errorMessage && (
      <Alert variant="danger" className="text-input-alert mt-2">
        {errorMessage}
      </Alert>
    )}
  </div>
);

export default TextInput;

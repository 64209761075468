// PreviewContent.js

import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Row, Col, Carousel } from 'react-bootstrap';
import SkeletonLoader from '../../ui/SkeletonLoader';

const PreviewContent = forwardRef(({ previewContent, propertyListingData, keyFeatures }, ref) => {

    const handleCopyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            // No need for alert here, as we are handling the user feedback in GenerateListing.js  
        } catch (err) {
            throw new Error('Failed to copy text to clipboard');
        }
    };

    const getTextToCopy = () => {
        const keyFeaturesText = keyFeatures.join('\n - ');
        const descriptionText = propertyDescription;
        return `Key Features\n - ${keyFeaturesText}\n\n${descriptionText}`;
    };


    const componentRef = useRef();

    const handleDownloadPdf = async () => {
        // Temporarily disable animations  
        const elementsWithFadeIn = componentRef.current.querySelectorAll('.fade-in');
        elementsWithFadeIn.forEach(el => el.style.animation = 'none');

        const canvas = await html2canvas(componentRef.current);
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'px',
            format: [canvas.width, canvas.height]
        });

        // Margins for the PDF, adjust as needed  
        const margin = {
            top: 40,
            right: 40,
            bottom: 40,
            left: 40
        };

        // Calculate the width and height, considering the margins  
        const contentWidth = canvas.width - margin.left - margin.right;
        const contentHeight = canvas.height - margin.top - margin.bottom;

        pdf.addImage(imgData, 'PNG', margin.left, margin.top, contentWidth, contentHeight);
        pdf.save('property-listing.pdf');

        // Re-enable animations  
        elementsWithFadeIn.forEach(el => el.style.animation = '');
    };


    useImperativeHandle(ref, () => ({
        handleDownloadPdf,
        handleCopyToClipboard,
        getTextToCopy, // Expose this function so it can be called from the parent  
    }));




    const isLoading = !previewContent || !keyFeatures.length;

    const propertyDescription = previewContent?.propertyDescription || '';

    const descriptionParagraphs = propertyDescription.split('\n').map((line, index) => (
        // If the line is not empty, render it in a <p> tag. Otherwise, return null or a <br/> tag  
        line ? <p key={index}>{line}</p> : null
    ));

    // Create image URLs from the file objects
    const imageUrls = propertyListingData.propertyImages.selectedFiles.map(file =>
        URL.createObjectURL(file)
    );

    // Group images for the carousel, first image in its own group, the next two in a sub-group
    const groupedImages = [];
    for (let i = 0; i < imageUrls.length; i += 3) {
        groupedImages.push([
            [imageUrls[i]], // First image in its own group
            imageUrls.slice(i + 1, i + 3) // Second and third image in a sub-group
        ]);
    }

    // Make sure to revoke the object URLs to avoid memory leaks when the component unmounts
    React.useEffect(() => {
        return () => {
            imageUrls.forEach(url => URL.revokeObjectURL(url));
        };
    }, [imageUrls]);

    return (  
        <div ref={componentRef}>
            {isLoading ? (
                <>
                    <Row>
                        <Col md={8}><SkeletonLoader height={160} /></Col>
                        <Col md={4}>
                            <Row>
                                <Col md={12}><SkeletonLoader height={70} /></Col>
                            </Row>
                            <Row>
                                <Col md={12}><SkeletonLoader height={70} /></Col>
                            </Row>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col md={3}><SkeletonLoader height={20} /></Col>
                        <Col md={9}></Col>
                    </Row>
                    <Row>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                    </Row>
                    <Row>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col md={3}><SkeletonLoader height={20} /></Col>
                        <Col md={9}></Col>
                    </Row>
                    <Row>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                    </Row>
                    <Row>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                        <Col md={4}><SkeletonLoader height={10} /></Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                    </Row>
                    <Row>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                    </Row>
                    <Row>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                    </Row>
                    <Row>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                    </Row>
                    <Row>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                        <Col md={6}><SkeletonLoader height={20} /></Col>
                    </Row>
                </>
            ) : (
                <>
                    <div className="fade-in">
                        {groupedImages.length > 0 && (
                            <Carousel interval={null}>
                                {groupedImages.map((group, index) => (
                                    <Carousel.Item key={index}>
                                        <Row className="mb-3">
                                            <Col md={8} className="bento-box-large">
                                                <img src={group[0][0]} alt={`Property ${index * 3 + 1}`} className="w-100" />
                                            </Col>
                                            <Col md={4}>
                                                {group[1].map((imageUrl, imgIndex) => (
                                                    <div key={imgIndex} className="bento-box-small mb-2">
                                                        <img src={imageUrl} alt={`Property ${index * 3 + imgIndex + 2}`} className="w-100" />
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                        <div className='preview-heading'>{propertyListingData.propertyLocation.neighbourhood}, {propertyListingData.propertyLocation.postcode}</div>
                        <div className='preview-price-pcm'>£{propertyListingData.rentDetails.price} pcm<span className='preview-price-pw'>£{Math.round((propertyListingData.rentDetails.price * 12) / 52)} pw</span></div>
                        <div className='preview-divider'></div>
                        <div className='preview-heading'>Letting Details</div>
                        <Row>
                            <Col md={4}>
                                <div className='preview-letting-detail-title'>Let Available Date:<span className='preview-letting-detail-value'>{propertyListingData.rentDetails.occupancyDate ? propertyListingData.rentDetails.occupancyDate : 'N/A'}</span></div>
                            </Col>
                            <Col md={4}>
                                <div className='preview-letting-detail-title'>Deposit:<span className='preview-letting-detail-value'>{propertyListingData.rentDetails.deposit ? '£' + propertyListingData.rentDetails.deposit : 'N/A'}</span></div>
                            </Col>
                            <Col md={4}>
                                <div className='preview-letting-detail-title'>Min. Tenancy:<span className='preview-letting-detail-value'>{propertyListingData.rentDetails.leaseLength ? propertyListingData.rentDetails.leaseLength : 'N/A'}</span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <div className='preview-letting-detail-title'>Let Type:<span className='preview-letting-detail-value'>{propertyListingData.rentDetails.letDuration ? propertyListingData.rentDetails.letDuration.label : 'N/A'}</span></div>
                            </Col>
                            <Col md={4}>
                                <div className='preview-letting-detail-title'>Furnish Type:<span className='preview-letting-detail-value'>{propertyListingData.rentDetails.furnishType ? propertyListingData.rentDetails.furnishType.label : 'N/A'}</span></div>
                            </Col>
                            <Col md={4}>
                                <div className='preview-letting-detail-title'>Council Tax:<span className='preview-letting-detail-value'>{propertyListingData.propertyFeatures.councilTax ? propertyListingData.propertyFeatures.councilTax.label : 'N/A'}</span></div>
                            </Col>
                        </Row>
                        <div className='preview-divider'></div>
                        <Row>
                            <Col md={3}>
                                <div className='preview-letting-detail-title'>PROPERTY TYPE</div>
                            </Col>
                            <Col md={3}>
                                <div className='preview-letting-detail-title'>BEDROOMS</div>
                            </Col>
                            <Col md={3}>
                                <div className='preview-letting-detail-title'>BATHROOMS</div>
                            </Col>
                            <Col md={3}>
                                <div className='preview-letting-detail-title'>SIZE</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <div className='preview-letting-detail-value'>{propertyListingData.propertyFeatures.propertyType.label ? propertyListingData.propertyFeatures.propertyType.label : 'N/A'}</div>
                            </Col>
                            <Col md={3}>
                                <div className='preview-letting-detail-value'>{propertyListingData.propertyFeatures.bedrooms.label}</div>
                            </Col>
                            <Col md={3}>
                                <div className='preview-letting-detail-value'>{propertyListingData.propertyFeatures.bathrooms.label}</div>
                            </Col>
                            <Col md={3}>
                                <div className='preview-letting-detail-value'>{propertyListingData.propertyFeatures.floorArea ? propertyListingData.propertyFeatures.floorArea + ' sq. ft.' : 'N/A'}</div>
                            </Col>
                        </Row>
                        <div className='preview-divider'></div>
                        <div className='preview-heading'>Key Features</div>
                        <Row>

                            <div className="fade-in">
                                <Row>
                                    <Col md={6}>
                                        <ul>
                                            {keyFeatures.slice(0, Math.ceil(keyFeatures.length / 2)).map((feature, index) => (
                                                <li key={index}>
                                                    {feature} {/* Step 4: Use feature directly instead of feature.label */}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                    <Col md={6}>
                                        <ul>
                                            {keyFeatures.slice(Math.ceil(keyFeatures.length / 2)).map((feature, index) => (
                                                <li key={index}>
                                                    {feature} {/* Use feature directly instead of feature.label */}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        <div className='preview-heading'>Property Description</div>

                        <div>
                            {descriptionParagraphs}
                        </div>
                    </div>
                </>
            )}

        </div>
    );
});

export default PreviewContent;

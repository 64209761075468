import React from 'react';  
  
const DateInput = ({ className, id, label, onChange, required, value }) => (  
  <div className={className}>  
    <label htmlFor={id} className="col-form-label">{label}</label>  
    <input 
      type="date" 
      className="form-control" 
      id={id}
      onChange={onChange} 
      required={required}
      value={value} 
    />  
  </div>  
);  
  
export default DateInput;  

// GenerateListing.js

// React components
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Service
import FetchDraft from './FetchDraft'; // Import the FetchDraft component  
import FetchEvaluation from './FetchEvaluation';
import PreviewContent from './PreviewContent';
import FeedbackModal from '../../ui/FeedbackModal';

// UI components
import StandardPage from '../StandardPage';
import { Container, Row, Col, Button, Tab, Tabs, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaVial, FaEye, FaDownload, FaRegThumbsDown, FaRegThumbsUp, FaRegClipboard } from 'react-icons/fa';

const GenerateListing = () => {
    // Get the navigate and location hooks
    const navigate = useNavigate();
    const location = useLocation();
    const propertyListingData = location.state || {};

    // State variables
    const [activeTab, setActiveTab] = useState('preview');
    const [evaluationResults, setEvaluationResults] = useState(null);
    const [lastEvaluatedContent, setLastEvaluatedContent] = useState('');
    const [currentDraftContent, setCurrentDraftContent] = useState('');
    const [draftModified, setDraftModified] = useState(false);
    const [previewContent, setPreviewContent] = useState('');
    const [keyFeatures, setKeyFeatures] = useState([]); // Step 1: Add state for key features  
    const [copyTooltip, setCopyTooltip] = useState('Copy');

    // Feedback modal state
    const [showModal, setShowModal] = useState(false);  
    const [feedbackType, setFeedbackType] = useState('like');
    const [feedback, setFeedback] = useState('');  
    const handleShowModal = (type) => {  
        setFeedbackType(type); // Update the feedbackType state with the received type  
        setShowModal(true);  
    };
    const handleCloseModal = () => {  
        setShowModal(false);  
        setFeedback(''); // clear feedback on close  
    };  
    const handleSubmitFeedback = async (feedbackText) => {  
        try {  
            const response = await fetch('/api/submitFeedback', {  // This should match the route of your submitFeedback function  
                method: 'POST',  
                headers: {  
                    'Content-Type': 'application/json'  
                },  
                body: JSON.stringify({  
                    feedbackType: feedbackType,  
                    feedbackText: feedbackText  
                })  
            });  
  
            if (!response.ok) {  
                throw new Error('Failed to submit feedback');  
            }  
  
            // Handle response data here  
            const responseData = await response.json();  
            console.log('Feedback submitted:', responseData);  
  
            // Assuming you want to close the modal and clear the feedback after submission  
            setShowModal(false);  
            setFeedback('');  
        } catch (error) {  
            // Handle any errors here  
            console.error('Error submitting feedback:', error);  
        }  
    };   
  

    const isLoading = !previewContent || keyFeatures.length === 0;

    // Callback function to update the current draft content
    const handleDraftFetched = (fetchedContent) => {
        if (currentDraftContent !== fetchedContent) {
            setCurrentDraftContent(fetchedContent);
            setKeyFeatures(fetchedContent.keyFeatures || []); // Update key features state  
            setDraftModified(true);
        }
    };

    // Function to handle the back button
    const handleBack = () => {
        navigate('/listing/rent', {
            state: {
                ...propertyListingData
            },
        });
    };

    const renderTooltip = (props, message) => (  
        <Tooltip id="button-tooltip" {...props}>  
            {message}  
        </Tooltip>  
    );    

    // This function would be inside GenerateListing.js  
    const copyTextToClipboard = () => {
        const textToCopy = previewRef.current.getTextToCopy(); // Assume this function is exposed via ref  
        previewRef.current.handleCopyToClipboard(textToCopy)
            .then(() => {
                setCopyTooltip('Copied'); // Update the tooltip to show 'Copied!'  
                setTimeout(() => setCopyTooltip('Copy'), 2000); // Reset tooltip text after 2 seconds  
            })
            .catch((err) => {
                console.error('Failed to copy text to clipboard', err);
            });
    };

    // Update preview content whenever the current draft content changes
    useEffect(() => {
        // Function to format the draft content for preview
        const formatForPreview = (draftContent) => {
            // Assuming draftContent is an object with keyFeatures and propertyDescription
            // Add any necessary formatting logic here
            return {
                keyFeatures: draftContent.keyFeatures,
                propertyDescription: draftContent.propertyDescription,
            };
        };

        setPreviewContent(formatForPreview(currentDraftContent));
    }, [currentDraftContent]);

    const previewRef = useRef();

    return (
        <StandardPage>
            <FeedbackModal  
                show={showModal}  
                onHide={handleCloseModal}  
                onFeedbackSubmit={handleSubmitFeedback}  
                feedbackType={feedbackType}
            />  
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8} className="listing-preview-col">
                        <h1 className="mb-2">Review and Edit Listing</h1>
                        <p className="mb-2">Edit, evaluate, and preview your property listing before it goes live.</p>
                        <Tabs
                            id="draft-evaluation-tabs"
                            className='listing-tabs'
                            activeKey={activeTab}
                            onSelect={(k) => {
                                setActiveTab(k);
                                if (k === 'evaluation' && draftModified) {
                                    setEvaluationResults(null); // Reset evaluation results to trigger re-evaluation  
                                    setLastEvaluatedContent(''); // Reset last evaluated content to ensure re-evaluation  
                                    setDraftModified(false); // Reset the draft modified flag  
                                }
                            }}
                        >
                            <Tab eventKey="preview" title={<span><FaEye /> Preview</span>}>
                                <div class="toolbar-container">
                                    <OverlayTrigger placement="top" overlay={(props) => renderTooltip(props, "Like")}>
                                        <Button
                                            className='toolbar-button'
                                            variant="custom"
                                            disabled={isLoading}
                                            onClick={() => handleShowModal('like')}
                                        >
                                            <FaRegThumbsUp />
                                        </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger placement="top" overlay={(props) => renderTooltip(props, "Dislike")}>
                                        <Button
                                            className='toolbar-button'
                                            variant="custom"
                                            disabled={isLoading}
                                            onClick={() => handleShowModal('dislike')}
                                        >
                                            <FaRegThumbsDown />
                                        </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={(props) => renderTooltip(props, copyTooltip)}
                                    >
                                        <Button
                                            className='toolbar-button'
                                            variant="custom"
                                            onClick={copyTextToClipboard}
                                            disabled={isLoading}
                                        >
                                            <FaRegClipboard />
                                        </Button>
                                    </OverlayTrigger>


                                    <OverlayTrigger placement="top" overlay={(props) => renderTooltip(props, "Download")}>
                                        <Button
                                            className='toolbar-button'
                                            variant="custom"
                                            onClick={() => previewRef.current.handleDownloadPdf()}
                                            disabled={isLoading}
                                        >
                                            <FaDownload />
                                        </Button>
                                    </OverlayTrigger>
                                </div>

                                <PreviewContent
                                    ref={previewRef}
                                    previewContent={previewContent}
                                    propertyListingData={propertyListingData}
                                    keyFeatures={keyFeatures}
                                />
                            </Tab>
                            <Tab eventKey="draft" title={<span><FaEdit /> Edit</span>}>
                                <FetchDraft
                                    propertyListingData={propertyListingData}
                                    onDraftFetched={handleDraftFetched}
                                />
                            </Tab>
                            <Tab eventKey="evaluation" title={<span><FaVial /> Evaluate</span>}>
                                {activeTab === 'evaluation' && (
                                    <FetchEvaluation
                                        currentDraftContent={currentDraftContent}
                                        lastEvaluatedContent={lastEvaluatedContent}
                                        setLastEvaluatedContent={setLastEvaluatedContent}
                                        evaluationResults={evaluationResults}
                                        setEvaluationResults={setEvaluationResults}
                                    />
                                )}
                            </Tab>

                        </Tabs>
                        <div className="d-flex justify-content-between">
                            <Button variant="secondary" onClick={handleBack} className="mt-3">
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StandardPage>
    );
};

export default GenerateListing;

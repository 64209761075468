import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button, Dropdown } from 'react-bootstrap';
import { BsPersonFill, BsQuestionCircleFill, BsBoxArrowRight } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

const NavBar = () => {
    const [userDetails, setUserDetails] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        fetch('/.auth/me')
            .then(response => response.json())
            .then(data => {
                if (data.clientPrincipal !== null) {
                    setIsLoggedIn(true);
                    setUserDetails(data.clientPrincipal);
                }
            });
    }, []);

    const handleSignIn = () => {
        window.location.href = "/.auth/login/aad?post_login_redirect_uri=/";
    };

    const handleSignOut = () => {
        window.location.href = "/.auth/logout?post_logout_redirect_uri=/";
    };

    const location = useLocation();
    const isActive = (pathname) => location.pathname === pathname;

    return (
        <Navbar expanded={expanded} expand="lg" className="justify-content-between">
            <Container fluid>
                <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>RealtyGen</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}>
                    <div className={`navbar-toggler-icon ${expanded ? 'open' : ''}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </Navbar.Toggle>

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto nav-container">
                        {isLoggedIn && (
                            <Nav.Link as={Link} to="/listing/location" active={isActive('/listing/location')} onClick={() => setExpanded(false)}>Create Listing</Nav.Link>
                        )}
                        <Nav.Link as={Link} to="/features" active={isActive('/features')} onClick={() => setExpanded(false)}>Features</Nav.Link>
                        <Nav.Link as={Link} to="/pricing" active={isActive('/pricing')} onClick={() => setExpanded(false)}>Pricing</Nav.Link>
                        <Nav.Link as={Link} to="/contact" active={isActive('/contact')} onClick={() => setExpanded(false)}>Contact Us</Nav.Link>
                    </Nav>
                    {isLoggedIn ? (
                        <Dropdown className='accountMenu'>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <BsPersonFill className="me-2" />
                                {userDetails.userDetails}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/account">
                                    <BsPersonFill className="me-2" />
                                    Account
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/contact">
                                    <BsQuestionCircleFill className="me-2" />
                                    Help
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleSignOut}>
                                    <BsBoxArrowRight className="me-2" />
                                    Sign out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <Button className="navButton signInButton" onClick={handleSignIn}>Sign in</Button>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;  

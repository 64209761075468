import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle, FaSpinner } from 'react-icons/fa'; // Import both FaInfoCircle and FaSpinner

const CreatableSelectInput = ({
  className,
  id,
  label,
  options,
  onChange,
  value,
  isLoading,
  isDisabled,
  tooltipText, // Add tooltipText prop
  tooltipId // Add tooltipId prop
}) => (
  <div className={className}>
    <label htmlFor={id} className="col-form-label">
      {label}
      {tooltipText && ( // Check if tooltip text is provided
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={tooltipId || `tooltip-${id}`}>
              {tooltipText}
            </Tooltip>
          }
        >
          <span>
            <FaInfoCircle className="info-icon ml-2" style={{ cursor: 'pointer' }} />
          </span>
        </OverlayTrigger>
      )}
    </label>
    <div style={{ position: 'relative' }}>
      <CreatableSelect
        id={id}
        options={options}
        onChange={onChange}
        isMulti
        menuPlacement="auto"
        value={value}
        isDisabled={isDisabled}
      />
      {isLoading && (
        <div className="spinner-container">
          <FaSpinner className="spinner" />
        </div>
      )}
    </div>
  </div>
);

export default CreatableSelectInput;

// AlertComponent.js  
import React, { useEffect } from 'react';  
import { Alert } from 'react-bootstrap';  
  
const AlertComponent = ({ message, type, show, onClose }) => {  
    // Set a timeout to automatically dismiss the alert after it's been shown  
    useEffect(() => {  
        let timeoutId;  
        if (show) {  
            timeoutId = setTimeout(onClose, 3000);  
        }  
          
        return () => {  
            if (timeoutId) {  
                clearTimeout(timeoutId);  
            }  
        };  
    }, [show, onClose]);  
  
    return (  
        <Alert variant={type} show={show} onClose={onClose} dismissible>  
            {message}  
        </Alert>  
    );  
};  
  
export default AlertComponent;  

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa'; // Import FaInfoCircle for the tooltip icon

const NumberInput = ({
  className,
  id,
  label,
  min,
  max,
  required,
  beforeText,
  afterText,
  onChange,
  onBlur,
  value,
  tooltipText, // Add tooltipText prop
  tooltipId // Add tooltipId prop
}) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="col-form-label">
        {label}
        {tooltipText && ( // Check if tooltip text is provided
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={tooltipId || `tooltip-${id}`}>
                {tooltipText}
              </Tooltip>
            }
          >
            <span>
              <FaInfoCircle className="info-icon ml-2" style={{ cursor: 'pointer' }} />
            </span>
          </OverlayTrigger>
        )}
      </label>
      <div className="input-group">
        {beforeText && <span className="input-group-text">{beforeText}</span>}
        <input
          type="number"
          min={min}
          max={max}
          className="form-control"
          id={id}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ''} // Control the value with React state
        />
        {afterText && <span className="input-group-text">{afterText}</span>}
      </div>
    </div>
  );
};

export default NumberInput;

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import LandingPage from './components/pages/LandingPage';
import AccountPage from './components/pages/AccountPage';
import ContactPage from './components/pages/ContactPage';
import FeaturesPage from './components/pages/FeaturesPage';
import PricingPage from './components/pages/PricingPage';
import PropertyLocation from './components/pages/listing/PropertyLocation';
import PropertyImages from './components/pages/listing/PropertyImages';
import PropertyFeatures from './components/pages/listing/PropertyFeatures';
import RentDetails from './components/pages/listing/RentDetails';
import GenerateListing from './components/pages/listing/GenerateListing';

// Custom hook to fetch and store the user's authentication state and roles
const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('/.auth/me');
        const payload = await response.json();
        setUser(payload.clientPrincipal); // Store the clientPrincipal object
      } catch (error) {
        console.error('Error fetching auth details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  return { user, loading };
};

const RequireAuth = ({ children, role }) => {
  const { user, loading } = useAuth();

  if (loading) {
    // Optional: Render a loading indicator while checking the user's role
    return <div>Loading...</div>;
  }

  if (!user) {
    // Redirect to the sign-in page if the user is not authenticated
    return <Navigate to="/sign-in" />;
  } else if (role && (!user.userRoles || !user.userRoles.includes(role))) {
    // Redirect to the landing page if the user doesn't have the required role
    return <Navigate to="/" />;
  }

  return children; // If the user is authenticated and has the role, render the children components
};

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/listing/location" element={<RequireAuth role="active"><PropertyLocation /></RequireAuth>}/>
      <Route path="/listing/images" element={<RequireAuth role="active"><PropertyImages /></RequireAuth>}/>
      <Route path="/listing/features" element={<RequireAuth role="active"><PropertyFeatures /></RequireAuth>}/>
      <Route path="/listing/rent" element={<RequireAuth role="active"><RentDetails /></RequireAuth>}/>
      <Route path="/listing/generate" element={<RequireAuth role="active"><GenerateListing /></RequireAuth>}/>
      <Route path="/account" element={<AccountPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      {/* Add other routes here */}
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// SkeletonLoader.js  
  
import React from 'react';  
import '../../css/SkeletonLoader.css';
  
const SkeletonLoader = ({ height }) => {  
  return <div className="skeleton-loader" style={{ height: `${height}px` }}></div>;  
};  
  
export default SkeletonLoader;  

// PropertyLocation.js  

// React components
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Services
import usePostcodeValidation from './usePostcodeValidation';
import { getNearestSchools, getNearestStations, getNearestRestaurants, getNearestParks } from './fetchAmenityData';

// UI components
import StandardPage from '../StandardPage';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import TextInput from '../../ui/TextInput';
import CreatableSelectInput from '../../ui/CreatableSelectInput';

const PropertyLocation = () => {
    
    // Get the navigate and location hooks
    const navigate = useNavigate();
    const location = useLocation();
    const propertyListingData = location.state || {};

    // Set the initial state based on the propertyListingData
    const [postcode, setPostcode] = useState(propertyListingData.propertyLocation?.postcode || '');
    const [nearbyStations, setNearbyStations] = useState(propertyListingData.propertyLocation?.nearbyStations || []);
    const [nearbySchools, setNearbySchools] = useState(propertyListingData.propertyLocation?.nearbySchools || []);
    const [nearbyRestaurants, setNearbyRestaurants] = useState(propertyListingData.propertyLocation?.nearbyRestaurants || []);
    const [nearbyParks, setNearbyParks] = useState(propertyListingData.propertyLocation?.nearbyParks || []);
    const { isPostcodeValid, neighbourhood, neighbourhoodLoading, longitude, latitude, setNeighbourhood, validateAndFetchNeighbourhoodData } = usePostcodeValidation();

    // Loading states for the select inputs
    const [postcodeFocused, setPostcodeFocused] = useState(false);
    const [schoolsLoading, setSchoolsLoading] = useState(false);
    const [stationsLoading, setStationsLoading] = useState(false);
    const [restaurantsLoading, setRestaurantsLoading] = useState(false);
    const [parksLoading, setParksLoading] = useState(false);
    const [lastValidatedPostcode, setLastValidatedPostcode] = useState('');  


    // Options for the select inputs
    const [stationsOptions, setStationsOptions] = useState(propertyListingData.propertyOptions?.stationsOptions || []);
    const [schoolsOptions, setSchoolsOptions] = useState(propertyListingData.propertyOptions?.schoolsOptions || []);
    const [restaurantsOptions, setRestaurantsOptions] = useState(propertyListingData.propertyOptions?.restaurantsOptions || []);
    const [parksOptions, setParksOptions] = useState(propertyListingData.propertyOptions?.parksOptions || []);

    // Tooltip text for the neighbourhood input
    const isNeighbourhoodDisabled = neighbourhoodLoading || !isPostcodeValid || postcode.length === 0 || postcodeFocused;
    const neighborhoodTooltipText = isNeighbourhoodDisabled
        ? "Enter a valid postcode to automatically fetch the neighbourhood name."
        : "You can overwrite the default neighbourhood value.";

    const fetchAmenitiesData = async (latitude, longitude) => {
        if (!latitude || !longitude) return;

        const fetchSchools = async () => {
            setSchoolsLoading(true);
            const schools = await getNearestSchools(latitude, longitude);
            setSchoolsOptions(schools);
            setSchoolsLoading(false);
        };

        const fetchStations = async () => {
            setStationsLoading(true);
            const stations = await getNearestStations(latitude, longitude);
            setStationsOptions(stations);
            setStationsLoading(false);
        };

        const fetchRestaurants = async () => {
            setRestaurantsLoading(true);
            const restaurants = await getNearestRestaurants(latitude, longitude);
            setRestaurantsOptions(restaurants);
            setRestaurantsLoading(false);
        };

        const fetchParks = async () => {
            setParksLoading(true);
            const parks = await getNearestParks(latitude, longitude);
            setParksOptions(parks);
            setParksLoading(false);
        };

        // Trigger all the fetch functions without waiting for each other
        fetchSchools();
        fetchStations();
        fetchRestaurants();
        fetchParks();
    };


    // Check if there's a neighbourhood in the propertyListingData and update the hook's state  
    // useEffect(() => {
    //     if (propertyListingData.propertyLocation?.neighbourhood) {
    //         setNeighbourhood(propertyListingData.propertyLocation.neighbourhood);
    //     }
    // }, [neighbourhood, setNeighbourhood]);

    useEffect(() => {  
        const propertyNeighbourhood = propertyListingData.propertyLocation?.neighbourhood;  
        if (propertyNeighbourhood && propertyNeighbourhood !== neighbourhood) {  
            setNeighbourhood(propertyNeighbourhood);  
        }  
    }, [propertyListingData]); // Depend on the propertyListingData to update the neighbourhood 

    const clearAmenities = () => {
        setStationsOptions([]);
        setNearbyStations([]);
        setSchoolsOptions([]);
        setNearbySchools([]);
        setRestaurantsOptions([]);
        setNearbyRestaurants([]);
        setParksOptions([]);
        setNearbyParks([]);
    };

    const handlePostcodeFocus = () => {
        setPostcodeFocused(true);
    };

    const handlePostcodeBlur = () => {
        setPostcodeFocused(false);
        // Clear the amenities if the postcode is empty
        if (!postcode.trim()) {
            clearAmenities();
            return;
        }
        // Only validate and fetch data if the postcode has changed  
        if (postcode !== lastValidatedPostcode) {
            validateAndFetchNeighbourhoodData(postcode, fetchAmenitiesData);
            setLastValidatedPostcode(postcode); // Update the last validated postcode  
        }
    };

    const handlePostcodeChange = (event) => {  
        setPostcode(event.target.value);  
        if (!event.target.value.trim()) {  
            setLastValidatedPostcode(''); // Reset last validated postcode  
            clearAmenities();  
        }  
    };  

    const handleSubmit = async (event) => {
        event.preventDefault();
        // await validateAndFetchNeighbourhoodData(postcode);  
        if (isPostcodeValid) {
            navigate('/listing/images', {
                state: {
                    ...propertyListingData,
                    propertyOptions: {
                        stationsOptions,
                        schoolsOptions,
                        restaurantsOptions,
                        parksOptions
                    },
                    propertyLocation: {
                        postcode,
                        isPostcodeValid,
                        neighbourhood,
                        longitude,
                        latitude,
                        nearbyStations,
                        nearbySchools,
                        nearbyRestaurants,
                        nearbyParks
                    }
                }
            });
        }
    };

    const handleBack = () => {
        navigate('/', {});
    };

    return (
        <StandardPage>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                        <h1 className="mb-2">Property Location</h1>
                        <p className="mb-2">Please enter the postcode to get the neighbourhood details.</p>
                        <Form onSubmit={handleSubmit}>
                            <div className='property-listing-form'>
                                <h2>Location Information</h2>
                                <p className='form-subtitle'>Please enter the postcode to get the neighbourhood details and local amenities.</p>
                                <Row>
                                    <Col md={6}>
                                        <TextInput
                                            id="postcode"
                                            label={<>Postcode<span className='required-input'>*</span></>}
                                            required={true}
                                            value={postcode}
                                            onChange={handlePostcodeChange}
                                            onFocus={handlePostcodeFocus}
                                            onBlur={handlePostcodeBlur}
                                            isInvalid={!isPostcodeValid}
                                            errorMessage="The postcode entered is invalid. Please try again."
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextInput
                                            id="neighbourhood"
                                            label="Neighbourhood"
                                            value={neighbourhood}
                                            onChange={event => setNeighbourhood(event.target.value)}
                                            disabled={neighbourhoodLoading || !isPostcodeValid || postcode.length === 0 || postcodeFocused}
                                            tooltipText={neighborhoodTooltipText}
                                            tooltipId="neighbourhood-tooltip"
                                        />
                                    </Col>
                                </Row>
                                <h2>Local Amenities</h2>
                                <p className='form-subtitle'>List local amenities such as nearby stations, schools, restaurants, and parks that add value to the property's location.</p>
                                <Row>
                                    <Col md={12}>
                                        <CreatableSelectInput
                                            id="stations"
                                            label="Nearby Stations"
                                            options={stationsOptions}
                                            isDisabled={stationsLoading || !isPostcodeValid || postcode.length === 0 || postcodeFocused}
                                            isLoading={stationsLoading}
                                            value={nearbyStations}
                                            onChange={(selectedOptions) => setNearbyStations(selectedOptions || [])}
                                            tooltipText="In addition to selecting stations from the list, you can type in the name of a station and press enter to add it to the list."
                                            tooltipId="station-tooltip"
                                        />
                                        <CreatableSelectInput
                                            id="schools"
                                            label="Nearby Schools"
                                            options={schoolsOptions}
                                            isDisabled={schoolsLoading || !isPostcodeValid || postcode.length === 0 || postcodeFocused}
                                            isLoading={schoolsLoading}
                                            value={nearbySchools}
                                            onChange={(selectedOptions) => setNearbySchools(selectedOptions || [])}
                                            tooltipText="In addition to selecting schools from the list, you can type in the name of a school and press enter to add it to the list."
                                            tooltipId="school-tooltip"
                                        />
                                        <CreatableSelectInput
                                            id="restaurants"
                                            label="Nearby Restaurants"
                                            options={restaurantsOptions}
                                            isDisabled={restaurantsLoading || !isPostcodeValid || postcode.length === 0 || postcodeFocused}
                                            isLoading={restaurantsLoading}
                                            value={nearbyRestaurants}
                                            onChange={(selectedOptions) => setNearbyRestaurants(selectedOptions || [])}
                                            tooltipText="In addition to selecting restaurants from the list, you can type in the name of a restaurant and press enter to add it to the list."
                                            tooltipId="restaurant-tooltip"
                                        />
                                        <CreatableSelectInput
                                            id="parks"
                                            label="Nearby Parks"
                                            options={parksOptions}
                                            isDisabled={parksLoading || !isPostcodeValid || postcode.length === 0 || postcodeFocused}
                                            isLoading={parksLoading}
                                            value={nearbyParks}
                                            onChange={(selectedOptions) => setNearbyParks(selectedOptions || [])}
                                            tooltipText="In addition to selecting parks from the list, you can type in the name of a park and press enter to add it to the list."
                                            tooltipId="park-tooltip"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col xs={12} className="d-flex mt-3">
                                    <Button variant="primary" type="submit" className="continue-button me-2">
                                        Continue
                                    </Button>
                                    <Button variant="secondary" onClick={handleBack} className="back-button">
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </StandardPage>
    );
};

export default PropertyLocation;

export const PROPERTY_TYPE_OPTIONS = [  
    { value: 'House', label: 'House' },  
    { value: 'Flat', label: 'Flat' },  
    { value: 'Apartment', label: 'Apartment' },  
    { value: 'Studio', label: 'Studio' },  
    { value: 'Townhouse', label: 'Townhouse' },  
    { value: 'Penthouse', label: 'Penthouse' },  
    { value: 'Terraced house', label: 'Terraced house' },  
    { value: 'Semi-detached house', label: 'Semi-detached house' },  
    { value: 'Detached house', label: 'Detached house' },  
    { value: 'Bungalow', label: 'Bungalow' },  
    { value: 'Cottage', label: 'Cottage' },  
    { value: 'Other', label: 'Other' },  
  ];  
    
  export const PROPERTY_CONDITION_OPTIONS = [  
    { value: 'Excellent condition', label: 'Excellent condition' },  
    { value: 'Newly refurbished', label: 'Newly refurbished' },  
    { value: 'Well-maintained', label: 'Well-maintained' },  
    { value: 'Good condition', label: 'Good condition' },  
    { value: 'Fair condition', label: 'Fair condition' },  
    { value: 'Poor condition', label: 'Poor condition' },  
    { value: 'In need of renovation', label: 'In need of renovation' }  
  ];  
    
  export const BEDROOM_OPTIONS = [  
    { value: '0', label: '0' },  
    { value: '1', label: '1' },  
    { value: '2', label: '2' },  
    { value: '3', label: '3' },  
    { value: '4', label: '4' },  
    { value: '5', label: '5' },  
    { value: '6', label: '6' },  
    { value: '7', label: '7' },  
    { value: '8', label: '8' },  
    { value: '9', label: '9' },  
    { value: '10', label: '10' }  
  ];  
    
  export const BATHROOM_OPTIONS = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ]
  
  export const COUNCIL_TAX_OPTIONS = [  
    { value: 'A', label: 'A' },  
    { value: 'B', label: 'B' },  
    { value: 'C', label: 'C' },  
    { value: 'D', label: 'D' },  
    { value: 'E', label: 'E' },  
    { value: 'F', label: 'F' },  
    { value: 'G', label: 'G' },  
    { value: 'H', label: 'H' }  
  ];  
    
  export const EPC_RATING_OPTIONS = [  
    { value: 'A', label: 'A' },  
    { value: 'B', label: 'B' },  
    { value: 'C', label: 'C' },  
    { value: 'D', label: 'D' },  
    { value: 'E', label: 'E' },  
    { value: 'F', label: 'F' },  
    { value: 'G', label: 'G' }  
  ];  
    
  export const LET_DURATION_OPTIONS = [  
    { value: 'long', label: 'Long-term' },  
    { value: 'mid', label: 'Mid-term' },  
    { value: 'short', label: 'Short-term' },  
    { value: 'flexible', label: 'Flexible' }  
  ];  
    
  export const BILLS_INCLUDED_OPTIONS = [  
    { value: 'Yes', label: 'Yes' },  
    { value: 'No', label: 'No' },  
    { value: 'Optional', label: 'Optional' },  
    { value: 'Partial', label: 'Partial' }  
  ];  

  export const FURNISHING_OPTIONS = [  
    { value: 'Furnished', label: 'Furnished' },  
    { value: 'Part-furnished', label: 'Part-furnished' },  
    { value: 'Unfurnished', label: 'Unfurnished' }  
  ];  
    
  export const PROPERTY_FEATURES_OPTIONS = [  
    { value: 'Balcony', label: 'Balcony' },  
    { value: 'Bay Window', label: 'Bay Window' },  
    { value: 'Cinema Room', label: 'Cinema Room' },  
    { value: 'Concierge Service', label: 'Concierge Service' },  
    { value: 'Fireplace', label: 'Fireplace' },  
    { value: 'Fitted Wardrobes', label: 'Fitted Wardrobes' },  
    { value: 'Garden', label: 'Garden' },  
    { value: 'Gym', label: 'Gym' },  
    { value: 'Integrated Appliances', label: 'Integrated Appliances' },  
    { value: 'Lift', label: 'Lift' },  
    { value: 'Open Plan Kitchen', label: 'Open Plan Kitchen' },  
    { value: 'Parking', label: 'Parking' },  
    { value: 'Pet-Friendly', label: 'Pet-Friendly' },  
    { value: 'Sauna', label: 'Sauna' },  
    { value: 'Skylight', label: 'Skylight' },  
    { value: 'Swimming Pool', label: 'Swimming Pool' },  
    { value: 'Terrace', label: 'Terrace' },  
    { value: 'Utility Room', label: 'Utility Room' },  
    { value: 'Wooden Floor', label: 'Wooden Floor' }  
  ];  
    
  export const BUILDING_AMENITIES_OPTIONS = [  
    { value: 'Bike storage', label: 'Bike storage' },  
    { value: 'Concierge service', label: 'Concierge service' },  
    { value: 'Day time porter', label: 'Day time porter' },  
    { value: 'Gym', label: 'Gym' },  
    { value: 'Landscaped gardens', label: 'Landscaped gardens' },  
    { value: 'Laundry room', label: 'Laundry room' },  
    { value: 'Meeting rooms', label: 'Meeting rooms' },  
    { value: 'Pet-friendly', label: 'Pet-friendly' },  
    { value: 'Private cinema screening room', label: 'Private cinema screening room' },  
    { value: 'Roof terrace', label: 'Roof terrace' },  
    { value: 'Security system', label: 'Security system' },  
    { value: 'Spa facilities', label: 'Spa facilities' },  
    { value: 'Swimming pool', label: 'Swimming pool' }  
  ];  
    
  export const SUITABLE_FOR_OPTIONS = [  
    { value: 'Suitable for couples', label: 'Suitable for couples' },  
    { value: 'Suitable for families', label: 'Suitable for families' },  
    { value: 'Suitable for HMO', label: 'Suitable for HMO' },  
    { value: 'Suitable for home office', label: 'Suitable for home office' },  
    { value: 'Suitable for pets', label: 'Suitable for pets' },  
    { value: 'Suitable for professionals', label: 'Suitable for professionals' },  
    { value: 'Suitable for sharers', label: 'Suitable for sharers' },  
    { value: 'Suitable for single occupants', label: 'Suitable for single occupants' },  
    { value: 'Suitable for students', label: 'Suitable for students' }  
  ];  
    
  export const RESTRICTIONS_OPTIONS = [  
    { value: 'No bike storage', label: 'No bike storage' },  
    { value: 'No bills included', label: 'No bills included' },  
    { value: 'No garden access', label: 'No garden access' },  
    { value: 'No HMO licence', label: 'No HMO licence' },  
    { value: 'No lift', label: 'No lift' },  
    { value: 'No parking', label: 'No parking' },  
    { value: 'No pets', label: 'No pets' },  
    { value: 'No short lets', label: 'No short lets' },  
    { value: 'No smoking', label: 'No smoking' },  
    { value: 'Not suitable for couples', label: 'Not suitable for couples' },  
    { value: 'Not suitable for families', label: 'Not suitable for families' },  
    { value: 'Not suitable for sharers', label: 'Not suitable for sharers' }  
  ];  
  